import React from 'react';

const Arrow = (props) => (
    <div onClick={props.onClick || null} aria-hidden="true" className="arrow" style={props.wrapperstyle || {}}>
        <svg style={props.style || {}}>
            <path
                d="M 11 0 L 11 19.5625 L 2.71875 11.28125 L 1.28125 12.71875 L 11.28125 22.71875 L 12 23.40625 L 12.71875 22.71875 L 22.71875 12.71875 L 21.28125 11.28125 L 13 19.5625 L 13 0 L 11 0 z"
                overflow="visible" />
        </svg>
    </div>
);

export default Arrow;