import React, {useEffect, useMemo, useState} from "react";
import 'animation.gsap'
import 'debug.addIndicators'
import Layout from "../components/layout"
import CodicateImage from "../components/codicate-image/index";
import GridSquares from "../components/grid-squares/index";
import Arrow from "../components/arrow/index";
import TimeLineCard from "../components/time-line-card/index";
import CardsSection from "../components/cards-section";
import ImagesSection from "../components/about-images-section";
import Carousel from 'nuka-carousel';
import aboutData from '../resources/data/about-data';
import {graphql} from 'gatsby';
import ScrollMagic from "scrollmagic";
import Utils from "../utils/utils";
// import debounce from 'lodash';
import {useOnScreen, usePrevious} from 'use-hooks';
import {IS_MOBILE} from "../utils/constants";

let timelineSection = React.createRef();
let imageSection = React.createRef();
let aboutSection = React.createRef();
let bottomSection = React.createRef();



const CardsContainer = ({cards}) => (
    <div className="cardsContainer desktop">
        <svg version="1.1" id="line1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             width="1613px" height="2016px" viewBox="0 0 1613 2016">
            <path fill="none" stroke="#FFC42E" d="M26,1v123.625v0.188v13.688c0,6.904-5.596,12.5-12.5,12.5S1,145.405,1,138.501
	s5.596-12.5,12.5-12.5h7.563l0.25-0.001H784c11.046,0,20,8.954,20,20v86c0,11.046,8.954,20,20,20h763v145.328v0.611v7.562
	c0,6.904,5.596,12.5,12.5,12.5s12.5-5.596,12.5-12.5s-5.596-12.5-12.5-12.5h-8.688l-0.578-0.001H1297c-11.046,0-20,8.954-20,20v171
	c0,11.046-8.954,20-20,20H524v273.439v0.374v8.688c0,6.904-5.596,12.5-12.5,12.5s-12.5-5.596-12.5-12.5s5.596-12.5,12.5-12.5h7.562
	l0.48-0.001H1277v211c0,11.046-8.954,20-20,20H511v524c0,11.046,8.954,20,20,20l477.032-0.001h0.775h8.688
	c6.903,0,12.5,5.597,12.5,12.5s-5.597,12.5-12.5,12.5s-12.5-5.597-12.5-12.5l0.004-7.563v-0.749V1506h476c11.046,0,20,8.954,20,20
	v388H851.766h-0.251h-8.39c-6.982,0-12.83-5.632-12.83-12.523s5.472-12.523,12.454-12.523s12.266,5.585,12.266,12.477v8.07
	l-0.015,0.266V1996c0,11.046-8.954,20-20,20H458"/>
        </svg>
        <svg version="1.1" id="line2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             width="1613px" height="2016px" viewBox="0 0 1613 2016">
            <path id="theMotionPath" fill="none" stroke="#FFC42E" d="M26,1v123.625v0.188v13.688c0,6.904-5.596,12.5-12.5,12.5S1,145.405,1,138.501
	s5.596-12.5,12.5-12.5h7.563l0.25-0.001H784c11.046,0,20,8.954,20,20v86c0,11.046,8.954,20,20,20h763v145.328v0.611v7.562
	c0,6.904,5.596,12.5,12.5,12.5s12.5-5.596,12.5-12.5s-5.596-12.5-12.5-12.5h-8.688l-0.578-0.001H1297c-11.046,0-20,8.954-20,20v171
	c0,11.046-8.954,20-20,20H524v273.439v0.374v8.688c0,6.904-5.596,12.5-12.5,12.5s-12.5-5.596-12.5-12.5s5.596-12.5,12.5-12.5h7.562
	l0.48-0.001H1277v211c0,11.046-8.954,20-20,20H511v524c0,11.046,8.954,20,20,20l477.032-0.001h0.775h8.688
	c6.903,0,12.5,5.597,12.5,12.5s-5.597,12.5-12.5,12.5s-12.5-5.597-12.5-12.5l0.004-7.563v-0.749V1506h476c11.046,0,20,8.954,20,20
	v388H851.766h-0.251h-8.39c-6.982,0-12.83-5.632-12.83-12.523s5.472-12.523,12.454-12.523s12.266,5.585,12.266,12.477v8.07
	l-0.015,0.266V1996c0,11.046-8.954,20-20,20H458"/>
            <circle cx="10px" cy="10px" r="5" fill="#ffc42e">
            </circle>
        </svg>
        {cards.map((card, index) => <TimeLineCard key={index} card={card}/>)}
    </div>
);

const handleTimelineCardVisibility = (isVisible, e) => {
    console.log(isVisible, e)
}
const Cards = ({cards: Cards,isMobile}) => {
    if (isMobile) {
        return (<CardsMobile cards={Cards}/>);
    } else {
        return (<CardsContainer cards={Cards}/>);
    }
}
const CardsMobile = ({cards: Cards}) => {
    const cards = [...Cards].reverse();
    const [slide, setSlide] = useState(0);
    const currentYear = cards[slide].year;
    return (
        <div className="cardsContainer mobile">
            <div className={'scroll-handlebars'}>
                <div/>
                <div/>
                <div/>
            </div>
            <Carousel slideIndex={slide} afterSlide={setSlide} vertical withoutControls>
                {cards.map((card, index) => (
                    <TimeLineCard key={index} onVisibilityChange={handleTimelineCardVisibility} card={card}/>
                ))}
            </Carousel>
            <div className={`mobile-timeline`}>
                {cards.map((card, k) => (<div onClick={() => setSlide(k)} key={k}
                                              className={`year ${currentYear === card.year ? 'current' : ''}`}>{card.year}</div>))}
            </div>
            <Arrow onClick={() => imageSection.current.scrollIntoView({behavior: "smooth"})}
                   wrapperstyle={{marginTop: '-35px', zIndex: 6, position: 'relative'}}
                   style={{
                       height: "27px",
                       maxHeight: 'initial',
                       fill: 'var(--main-yellow)',
                       marginTop: "-50px",
                       transform: 'scale(1.5)'
                   }}/>
        </div>
    );
};

const About = (props) => {
    const timeLineCards = useMemo(() => aboutData(props.data), props.data);
    const {orenAlonImg} = props.data;
    const scene = [];
    const [isMobile, setIsMobile] = useState(IS_MOBILE);

    useEffect(() => {
        if(IS_MOBILE) {
            setIsMobile(true)
        }

    }, []);


    // handling analytics scrolling events
    let refs = {
        aboutSectionOnScreen: {
            visible: useOnScreen(aboutSection, "-300px"),
            sent: false,
            displayName: 'About Section'
        },
        timelineSectionOnScreen: {
            visible: useOnScreen(timelineSection, "-300px"),
            sent: false,
            displayName: 'Timeline Section'
        },
        imageSectionOnScreen: {
            visible: useOnScreen(imageSection, "-300px"),
            sent: false,
            displayName: 'Card Section'
        },
        bottomSectionOnScreen: {
            visible: useOnScreen(bottomSection, "-300px"),
            sent: false,
            displayName: 'Bottom Section'
        }
    };


    const prevRefs = usePrevious(refs)

    useEffect(() => {
        Object.keys(refs).forEach((item, i) => {
            if (refs[item].visible && !refs[item].sent && !prevRefs[item].sent) {
                refs[item].sent = true;
                Utils.gTagEvent({
                    category: 'About',
                    label: `${i + 1} / ${refs[item].displayName}`,
                    action: 'Section View',
                    nonInteraction: true
                })
            }
        })

    }, [refs]);

    useEffect(() => {
        if (!IS_MOBILE) {
            //componentDidMount
            const controller = new ScrollMagic.Controller();

            // Get the id of the <path> element and the length of <path>
            const line = document.querySelector('#line2 path');
            const length = line.getTotalLength();

            const circle = document.querySelector('#line2 circle');

            // The start position of the drawing
            line.style.strokeDasharray = length;

            // Hide the triangle by offsetting dash. Remove this line to show the triangle before scroll draw
            line.style.strokeDashoffset = length;


            const animationDuration = document.querySelector('.time-line').offsetHeight;
            //line animation
            scene.push(new ScrollMagic.Scene({triggerElement: ".time-line", duration: animationDuration})
            // .addIndicators({name: '1 - background up'}) // add indicators
                .on("progress", function (event) {
                    // Length to offset the dashes
                    const draw = length * event.progress;

                    // Reverse the drawing (when scrolling upwards)
                    line.style.strokeDashoffset = length - draw;

                    //get point at length
                    const endPoint = line.getPointAtLength(draw);
                    circle.setAttribute("cx", endPoint.x);
                    circle.setAttribute("cy", endPoint.y);
                })
                .addTo(controller));

        }
        return () => {
            //componentDidUnMount

            scene.forEach(function (el) {
                el.destroy(true);
                el = null;
            });
        }
    }, []);

    return (
        <Layout hasGrisSquares={false} className="page-about dark whiteBg" isIncludeFooter={false}
                yoastData={props.data.wordpressPage.yoast || null}
                seoTitle={props.data.wordpressPage.acf.seoTitle}
                pageTitle={props.data.wordpressPage.title}
        >
            <GridSquares/>
            <section className="about-story whiteBg" ref={aboutSection}>
                <div className="image-area">
                    <CodicateImage blur img={orenAlonImg}/>
                </div>
                <div className="text-area">
                    <h1>ABOUT US</h1>
                    <p>Codicate is a family owned development studio, founded by Alon & Oren Roth in 2005. Through the
                        years, we’ve collected a group of talented professionals who share our
                        passion for creating beautiful digital products.</p>
                    <Arrow onClick={() => timelineSection.current.scrollIntoView({behavior: "smooth"})}
                           style={{
                               // height: "27px",
                               // maxHeight: 'initial',
                               left: 0,
                               top: 0
                           }}
                    />
                </div>
            </section>
            <section className="time-line blueBg" ref={timelineSection}>
                <GridSquares rowsAmount={29}/>
                <Cards cards={timeLineCards} isMobile={isMobile}/>
            </section>
            <CardsSection ref={imageSection}/>
            <ImagesSection ref={bottomSection}/>
        </Layout>
    );
};

export default About

export const query = graphql`
    query {
        wordpressPage(template: {eq: "about.php"}){
            title
            path
            ...YoastData
            acf {
                seoTitle: seo_title
            }
        }
        orenAlonImg: file(relativePath: { eq: "about/oren-alon.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 1091){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        flash: file(relativePath: { eq: "about/timeline/flash.png"}) {
            childImageSharp {
                fluid(maxWidth: 400){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        openSource: file(relativePath: { eq: "about/timeline/open-source.png"}) {
            childImageSharp {
                fluid(maxWidth: 300){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        walnuts: file(relativePath: { eq: "about/timeline/walnuts.png"}) {
            childImageSharp {
                fluid(maxWidth: 300){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        nana: file(relativePath: { eq: "about/timeline/nana.png"}) {
            childImageSharp {
                fluid(maxWidth: 300){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        usaBalloon: file(relativePath: { eq: "about/timeline/usa-balloon--1.png"}) {
            childImageSharp {
                fluid(maxWidth: 200){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        bl: file(relativePath: { eq: "about/timeline/bl.png"}) {
            childImageSharp {
                fluid(maxWidth: 200){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        sports: file(relativePath: { eq: "about/timeline/sports.png"}) {
            childImageSharp {
                fluid(maxWidth: 200){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        novartis: file(relativePath: { eq: "about/timeline/novartis.png"}) {
            childImageSharp {
                fluid(maxWidth: 200){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        weizmann: file(relativePath: { eq: "about/timeline/weizmann.png"}) {
            childImageSharp {
                fluid(maxWidth: 200){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        davidson: file(relativePath: { eq: "about/timeline/davidson.png"}) {
            childImageSharp {
                fluid(maxWidth: 200){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        telescope: file(relativePath: { eq: "about/timeline/telescope.png"}) {
            childImageSharp {
                fluid(maxWidth: 200){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        react: file(relativePath: { eq: "about/timeline/react.png"}) {
            childImageSharp {
                fluid(maxWidth: 200){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        verint: file(relativePath: { eq: "about/timeline/verint.png"}) {
            childImageSharp {
                fluid(maxWidth: 200){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        jolt: file(relativePath: { eq: "about/timeline/jolt.png"}) {
            childImageSharp {
                fluid(maxWidth: 200){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        joltPhones: file(relativePath: { eq: "about/timeline/jolt-phones.png"}) {
            childImageSharp {
                fluid(maxWidth: 350){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

    }
`;



