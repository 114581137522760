import React, {useEffect, useState} from 'react';
import CodicateImage from "../codicate-image/index";
import './style.scss';
import {IS_MOBILE} from "../../utils/constants";
const Icon = ( {image, style = {}} ) => (
    <div className="icon-container" style={style}>
        <CodicateImage img={image}/>
    </div>
);

const StyledImages = ({styledImages}) => styledImages.map(({image, style}, k) => (
    <div className="styled-image-container" key={k}>
        <CodicateImage className="styledImages" img={image} style={style} />
    </div>
)) || null;

const BottomIcons = ({bottomIcons}) => !bottomIcons.length ? null : (
    <div className="bottom-area">
        {bottomIcons.map((icon, index) => (
                <div key={index} className="bottom-icon-container" style={icon.style}>
                    <CodicateImage key={`${index}-${icon.image}`} img={icon.image} />
                </div>
            )
        )}
    </div>
);

const TimeLineCard = ({card}) => {
    const [isMobile, setIsMobile] = useState(IS_MOBILE);
    useEffect(() => {
        if(IS_MOBILE) {
            setIsMobile(true)
        }

    }, []);

    const { year, textBold, textRegular, icon, imageSideStyle, bottomIcons = [], cardStyle = {}, cardClass = '', styledImages = [] } = card;

    const mobileYearsNoSideClass = [2016, 2013];
    // const mobileYearsNoSideClass = [];

    const hideImageSide = isMobile && mobileYearsNoSideClass.find((item) => item === year);

    return (
        <div className={'card-wrapper'} id={"year_"+year}>
            <div className={`card-container ${cardClass}`} style={cardStyle}>
                {!hideImageSide && <div className="image-side" style={imageSideStyle}>
                    {icon && <Icon {...icon} />}
                    <StyledImages {...{styledImages}} />
                </div>}
                <div className="text-side">
                    <h2>{year}</h2>
                    {textBold && <h4>{textBold}</h4>}
                    {textRegular && <span>{textRegular}</span>}
                </div>
                <BottomIcons {...{bottomIcons}} />
            </div>
        </div>
    )
};

export default TimeLineCard;