import {CUBE_LENGTH} from "../../utils/constants";
import React from "react";

const timeLineXAdd = 0.5;

export default (data) => ([
    {
        year: 2005,
        textBold: 'It all began with flash sites…',
        textRegular: 'Young devs with a passion for web development',
        styledImages: [
            {
                image: data.flash,
                style: {
                    height: '17.188em',
                    width: '19.125em',
                    transform: 'translate(-2.2em, -2.7em)',
                }
            }
        ],
        icon: '',
        bottomIcons: [],
        cardStyle: {
            transform: `translate(calc(-6 * ${CUBE_LENGTH} + ${timeLineXAdd} * ${CUBE_LENGTH}), 0px)`,
        }
    },
    {
        year: 2008,
        textBold: 'Our first open source project',
        textRegular: '',
        styledImages: [],
        icon: {
            image: data.openSource,
            style: {
                width: '11.563em',
            }
        },
        bottomIcons: [],
        cardStyle: {
            transform: `translate(calc(5 * ${CUBE_LENGTH} + ${timeLineXAdd} * ${CUBE_LENGTH}), 0px)`,
        }
    },
    {
        year: 2011,
        textBold: 'Walnuts, our first feature on Techcrunch',
        textRegular: '',
        styledImages: [
            {
                image: data.walnuts,
                style: {
                    height: '8.063em',
                    width: '16.125em',
                    transform: 'translate(-4.2em, 0px)',
                }
            }
        ],
        icon: '',
        bottomIcons: [],
        cardStyle: {
            transform: `translate(calc(-4.5 * ${CUBE_LENGTH} + ${timeLineXAdd} * ${CUBE_LENGTH}), calc(1 * ${CUBE_LENGTH}))`,
        }
    },
    {
        year: 2012,
        textBold: 'cloud file storage system (even before Google Drive!)',
        textRegular: '',
        styledImages: [],
        icon: {
            image: data.nana,
            style: {
                width: '12.188em',
            }
        },
        bottomIcons: [],
        cardStyle: {
            transform: `translate(calc(5 * ${CUBE_LENGTH} + ${timeLineXAdd} * ${CUBE_LENGTH}), calc(1.2 * ${CUBE_LENGTH}))`,
        }
    },
    {
        year: 2013,
        textBold: 'Working with the top brands in the US',
        textRegular: '',
        styledImages: [
            {
                image: data.usaBalloon,
                style: {
                    height: '20.375em',
                    width: '7.875em',
                    // transform: 'translate(-4.2em, 0px)',
                }
            },
            {
                image: data.usaBalloon,
                style: {
                    transform: 'translate(0.4em,-7.4em)',
                    width: '7.15em',
                    height: '18.5em',
                }
            }
        ],
        icon: '',
        bottomIcons: [
            {
                image: data.sports,
                style: {
                    width: '7.375em',
                }
            },
            {
                image: data.bl,
                style: {
                    width: '4.813em',
                }
            },
            {
                image: data.novartis,
                style: {
                    width: '13.813em',
                }
            }
        ],
        imageSideStyle: {
            flexDirection: "row",
            justifyContent: 'flex-end',
        },
        cardStyle: {
            height: '12.688em',
            transform: `translate(calc(-6 * ${CUBE_LENGTH} + ${timeLineXAdd} * ${CUBE_LENGTH}), calc(1.4 * ${CUBE_LENGTH}))`,
        }
    },
    {
        year: 2016,
        textBold: 'Israel’s leading scientific website reaches 300K monthly visitors',
        textRegular: '',
        styledImages: [
            {
                image: data.telescope,
                style: {
                    height: '16.563em',
                    width: '13.125em',
                    transform: 'translate(0.1em, -2.7em)',
                }
            }
        ],
        icon: '',
        bottomIcons: [
            {
                image: data.davidson,
                style: {
                    width: '14.125em',
                    height: '2.688em',

                }
            },
            {
                image: data.weizmann,
                style: {
                    width: '14.188em',
                    height: '2.619em',
                }
            }
        ],
        imageSideStyle: {},
        cardStyle: {
            transform: `translate(calc(3 * ${CUBE_LENGTH} + ${timeLineXAdd} * ${CUBE_LENGTH}), calc(1.8 * ${CUBE_LENGTH}))`,
        }
    },
    {
        year: 2017,
        textBold: 'An advanced Webint system based on React.js',
        textRegular: '',
        styledImages: [
            {
                image: data.react,
                style: {
                    height: '5.938em',
                    width: '6.625em',
                    position: 'absolute',
                    transform: 'translate(-10.9em, -10.4em)',
                }
            },
            {
                image: data.react,
                style: {
                    height: '4.063em',
                    width: '4.563em',
                    position: 'absolute',
                    transform: 'translate(2.3em, 4.1em)',
                }
            }
        ],
        icon: {
            image: data.verint,
            style: {
                height: '2.063em',
                width: '11.938em',
            }
        },
        bottomIcons: [],
        imageSideStyle: {},
        cardStyle: {
            transform: `translate(calc(5 * ${CUBE_LENGTH} + ${timeLineXAdd} * ${CUBE_LENGTH}), calc(5 * ${CUBE_LENGTH}))`,
        }
    },
    {
        year: 2019,
        textBold: <label>A native app for the 21<sup>st</sup> century university</label>,
        textRegular: '',
        styledImages: [],
        icon: {
            image: data.jolt,
            style: {
                height: '6.444em',
                width: '10.875em',
            }
        },
        bottomIcons: [],
        imageSideStyle: {
            zIndex: 11,
        },
        cardStyle: {
            transform: `translate(calc(-5.8 * ${CUBE_LENGTH}), calc(4 * ${CUBE_LENGTH}))`,
            zIndex: 1,
        },
        cardClass: 'jolt-phones'
    },

])